import {
  call, put, delay, cancelled, takeLatest,
} from 'redux-saga/effects';
import { updateData } from '../redux/kioskSlice';
import sagaActions from './sagaActions';
import API from '../common/axios';
import { readSettings } from '../utils/board';

export function* poll() {
  while (true) {
    try {
      let boxId;
      let mode;
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        mode = fileData ? fileData.dynamicDataSource : '';
        boxId = fileData ? fileData.boxId : '';
      } else {
        boxId = localStorage.getItem('boxId');
        mode = localStorage.getItem('dynamicDataSource');
      }
      if (boxId && mode) {
        console.log('enter');
        const result = yield call(() => API.get(`apis/i-ecom/orders/${boxId}/list`));
        yield put(updateData(result.data));
        yield delay(60000);
      }
    } catch (error) {
      console.error('Polling error:', error);
      yield delay(60000); // Wait before retrying
    } finally {
      if (yield cancelled()) {
        console.log('Polling stopped.');
      }
    }
  }
}

function* pollWatcherSaga() {
  while (true) {
    yield call(poll);
  }
}

export default function* rootSaga() {
  yield takeLatest(sagaActions.FETCH_BOX, pollWatcherSaga);
}
