import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerObj: {},
};

export const kioskSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    updateCustomerObj: (state, action) => ({
      customerObj: { ...state.customerObj, ...action.payload },
    }),
  },
});

export const { updateCustomerObj } = kioskSlice.actions;
export default kioskSlice.reducer;
