/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './redux/store';
import * as Sentry from "@sentry/react";

const sentryIntegrations = [];

if (process.env.SENTRY_BROWSER_TRACING === 'true') {
  sentryIntegrations.push(Sentry.browserTracingIntegration());
}

if (process.env.SENTRY_REPLAY === 'true') {
  sentryIntegrations.push(Sentry.replayIntegration());
}


Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: sentryIntegrations,
  // Performance Monitoring
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACE_SAMPLE_RATE), //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/api.localbox.net/], // TODO: We need to handle tracking headers on the API side first
  initialScope: (scope) => {
    // Read the boxId from localStorage and set it as a tag
    scope.setTags({ boxId: localStorage.getItem('boxId') });
    return scope;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

reportWebVitals();
