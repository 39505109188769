import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  box: {},
};

export const kioskSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    updateData: (state, action) => ({
      box: { ...state.box, ...action.payload },
    }),
  },
});

export const { updateData } = kioskSlice.actions;
export default kioskSlice.reducer;
