import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import router from './router/router';
import './index.css';
import Loader from './components/fromComponents/Loader';
import sagaActions from './sagas/sagaActions';
import API from './common/axios';
import { updateData } from './redux/kioskSlice';
import { readSettings } from './utils/board';

function App() {
  const dispatch = useDispatch();

  const getBoxesData = async () => {
    let boxId;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }
    if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
      await API.get(`apis/i-ecom/orders/${boxId}/list`).then((res) => {
        dispatch(updateData(res.data));
      }).catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getBoxesData();
    dispatch({ type: sagaActions.FETCH_BOX });
  }, []);

  return (
    <Suspense
      fallback={(
        <div className="flex justify-center align-middle h-screen">
          <Loader />
        </div>
      )}
    >
      <RouterProvider router={router}>
        {/* Components will be rendered here */}
      </RouterProvider>
    </Suspense>
  );
}

export default App;
